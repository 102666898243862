import { type FormEvent, type ReactElement, useEffect, useState } from 'react';
import { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserConnection } from '@/sgwt/widgets.ts';
import { ApiConfigurationService } from '@/services/ApiConfigurationService.ts'
import { type ApplicationDto } from '@/models/services.ts'
import { type APIConfigurationDto, type APIEntryDto, type APIEntryExtDto, type CheckExistanceDto } from '@/models/apiConfiguration.ts'
import { Link } from 'react-router-dom';
import './settingsForm.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText  } from 'reactstrap';


export type SettingsFormProps = {
    application: ApplicationDto | null,
    closeModal: () => void;
    save: (producerCode: string | undefined, apiEntries : APIEntryDto[]) => void;
  };

  
const SettingsForm: FC<SettingsFormProps>= ({
    application,
    closeModal,
    save
}: SettingsFormProps) => {

  
    const [producerCode, setProducerCode] = useState(application?.producerCode)
    const [activeTab, setActiveTab] = useState(application?.environments[0].name);
    const [apiConfiguration, setApiConfiguration] = useState<APIConfigurationDto>();
    const [apiEntries, setApiEntries] = useState<APIEntryDto[]>([]);
    const [newApiEntries, setNewApiEntries] = useState<APIEntryExtDto[]>([]);
    
    const SaveSettings = () => {
        
        const newEntries = [...apiEntries];
        newApiEntries.map((entry) =>  {
            newEntries.push({
                apiCode: entry.apiCode,
                environment: entry.environment,
                serviceEnvironment: entry.serviceEnvironment
            });
        })
        save(producerCode, newEntries);
      };

    const AddApi = () => {
        setNewApiEntries([...newApiEntries, {
            id: newApiEntries.length,
            apiCode: "",
            environment: "",
            serviceEnvironment: activeTab!,
            isValidApiCode: 0,
            possibleEnvironments: null
        }]);        
    };
    

    const ChangeApiCode= (event: FormEvent<HTMLInputElement>, api: APIEntryExtDto): void  => {
        api.apiCode = event.currentTarget.value;
        const apiConfigurationService = new ApiConfigurationService();
        apiConfigurationService.checkApiExistance(api.apiCode).then((result: CheckExistanceDto) => {
            const newEntries = [...newApiEntries];
            newEntries[ api.id ].apiCode = api.apiCode;
            newEntries[ api.id ].isValidApiCode = result.apiCodeExist ? 1 : -1;
            newEntries[ api.id ].possibleEnvironments = result.possibleEnvironments;
            newEntries[ api.id ].environment = result.possibleEnvironments[0];
            setNewApiEntries( newEntries );
        });

    }
    const ChangeEnvironment= (event: FormEvent<HTMLInputElement>, api: APIEntryExtDto): void  => {
        api.environment = event.currentTarget.value;
        const newEntries = [...newApiEntries];
        newEntries[ api.id ].environment = api.environment;
        setNewApiEntries( newEntries );
    }

    const GetValidity = (validity: number): string => {
        return validity == -1 ? 'is-invalid' : validity== 1 ? 'is-valid' : '';
    }
    
    

    useEffect(() => {
        const apiConfigurationService = new ApiConfigurationService();
        let apisList : string[] = [];
        let entries: APIEntryDto[] = [];

        application?.environments.filter((env) => env.name == activeTab)[0].apis.map((api => (
            apisList.push(api.name)
        )));
        if (application != null) {
            apiConfigurationService.getConfigureApis(application.key, apisList).then((data) => {
                setApiConfiguration(data);
                data.configuration?.map((configuration) => {
                    entries.push({
                        apiCode: configuration.apiCode,
                        serviceEnvironment: configuration.serviceEnvironment,
                        environment: configuration.environment
                    });
                });
            }).finally(() => {
                application?.environments.map((env) => {
                    env.apis.map((api) => {
                        if (entries.filter(e => e.apiCode == api.name).length == 0) {
                            entries.push({
                                apiCode: api.name,
                                serviceEnvironment: env.name,
                                environment: api.selectedEnvironment
                            });
                        }
                    });
                });
                setApiEntries(entries);
            });
        }
    }, [activeTab]);

    const SetEnv = (name: string, value: string) => {
        let apiUpdated = false;
        if (apiEntries != undefined) {
            apiEntries.map((entry) => {
                if (entry.environment == "" || entry.environment == undefined) {
                    apiUpdated = true;
                    const env = apiConfiguration?.apiEntries.filter( (f) => f.apiCode == entry.apiCode )[0].environments[0];
                    if (env != undefined)
                        entry.environment = env.name;
                }
            });
            if (!apiUpdated) {
                apiEntries.push({
                    apiCode: name,
                    serviceEnvironment: activeTab!,
                    environment: name
                });
            }
        }
        apiEntries?.filter((f) => f.serviceEnvironment == activeTab && f.apiCode == name).map((entry) => {
            entry.environment = value;
        });
    };



  return (
    <div className="row pt-2  d-flex">
        <ModalHeader>Settings for {application?.name}</ModalHeader>
        <ModalBody>
            <Form className='pt-3'>
                <Label className='form-card-title'>Partial Incident:</Label>
                <FormGroup className='form-group-card'>
                <p className='mb-2 fst-italic'>Please provide, is you're using one, the producer code that your service is using for partial incicent.</p>  
                <Label for="producerCode">Producer code:</Label>
                    <Input
                        id="producerCode"
                        name="producerCode"
                        placeholder="Enter your producer code"
                        type="text"
                        onChange={event => setProducerCode(event.target.value)}
                        value={producerCode}
                    />
                </FormGroup>


                           
                <Label className='form-card-env-title'>Api Environments:</Label>  
                <FormGroup className='form-group-card'>
                <p className='mb-2 fst-italic'>Please provide, for each environments, the list of Apis that your service is using.</p>  
                <Nav tabs>
                    {application?.environments.map((env => (
                    <NavItem>
                    <NavLink className={activeTab == env.name ? 'active' : ''} onClick={() => setActiveTab(env.name)}>
                        {env.name}
                    </NavLink>
                    </NavItem>
                    )))}
                </Nav>
                <TabContent activeTab={activeTab}>
                    
                    {application?.environments?.map((env => (
                    <TabPane tabId={env.name} className='tabPane-env'>
                    <Row className='tab-max-height'>
                        <Col sm="12">
                        <h6 className='mb-2'>Apis</h6>
                         { env.apis?.filter((f) => apiEntries.filter((ae) => ae.apiCode == f.name).length == 0).map((api => (
                                <Row className='mb-2'>
                                    <Col sm="4" className='text-v-align'>{api.name}:</Col>
                                    <Col sm="8">
                                        <Input type="select" onChange={event => SetEnv(api.name, event.target.value)} >
                                        {
                                            apiConfiguration?.apiEntries?.filter( (f) => f.apiCode == api.name )[0]?.environments?.map((environment) =>  {
                                                let selectedEnv = apiConfiguration?.configuration.filter( (f) => f.apiCode == api.name && f.serviceEnvironment == env.name)[0]?.environment;
                                                if (selectedEnv == environment.name)
                                                    return <option selected>{ environment.name }</option>
                                                return <option>{ environment.name }</option>
                                            })
                                        }
                                        </Input>
                                    </Col>
                                </Row>
                            )))
                        }
                        { apiEntries.filter(apiEnv => apiEnv.serviceEnvironment == env.name)?.map((api => (
                                <Row className='mb-2'>
                                    <Col sm="4" className='text-v-align'>{api.apiCode}:</Col>
                                    <Col sm="8">
                                        <Input type="select" onChange={event => SetEnv(api.apiCode, event.target.value)} >
                                        {
                                            apiConfiguration?.apiEntries?.filter( (f) => f.apiCode == api.apiCode )[0]?.environments?.map((environment) =>  {
                                                let selectedEnv = apiConfiguration?.configuration.filter( (f) => f.apiCode == api.apiCode && f.serviceEnvironment == env.name)[0]?.environment;
                                                if (selectedEnv == environment.name)
                                                    return <option selected>{ environment.name }</option>
                                                return <option>{ environment.name }</option>
                                            })
                                        }
                                        </Input>
                                    </Col>
                                </Row>
                            )))
                        }
                        { newApiEntries.filter((f) => f.serviceEnvironment == activeTab).map((api => (
                            <Row className='mb-2'>
                                <Col sm="4" className='text-v-align'>
                                <Input type="text" placeholder="Enter Api Code" onInput={(evt) => ChangeApiCode(evt, api)}  className={GetValidity(api.isValidApiCode)} defaultValue={api.apiCode}></Input>
                                </Col>
                                <Col sm="8" className='text-v-align'>
                                    <Input type="select" onChange={event => ChangeEnvironment(event, api)}>
                                        {
                                            api?.possibleEnvironments?.map((environment) =>  {
                                                if (api.environment == environment)
                                                    return <option selected>{ environment }</option>
                                                return <option>{ environment }</option>
                                            })
                                        }
                                        </Input>
                                </Col>
                            </Row>
                        )))
                        }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className='text-end'>
                            <Button color="primary" onClick={AddApi}><i className="icon icon-md me-2">add</i>Add</Button>
                        </Col>
                    </Row>
                    </TabPane>
                    )))}
                </TabContent>
                </FormGroup>   
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={SaveSettings}>
            Save
            </Button>
            <Button color="secondary" onClick={closeModal}>
            Cancel
            </Button>
        </ModalFooter>

    </div>
  );
};

export default SettingsForm;