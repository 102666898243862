import { type ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { HealthGraphService } from '@/services/HealthGraphService.ts'

import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type HealthGraphDto, type ServiceGraphDto } from '@/models/healthGraph.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import './healthChart.css';
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Nav, NavLink, NavItem, Row, Col, ButtonGroup, Button  } from 'reactstrap';
import HealthTree from './healthTree.tsx'
import UnauthorizedAccess from './UnauthorizedAccess.tsx';
import ErrorPage from './ErrorPage.tsx';
import HealthMenu from './healthMenu.tsx';
import NoValueCompute from '../common/NoValueCompute.tsx';

export function HealthChart(): ReactElement {

    let { key } = useParams();
    let { env } = useParams();
    
    const navigate = useNavigate();
    
    const [application, setApplication] = useState<ApplicationDto>();
    const [currentEnvironment, setCurrentEnvironment] = useState<string>();
    const [showSearchText, setShowSearchText] = useState<string>('');
    const [healthGraphDto, setHealthGraphDto] = useState<ServiceGraphDto>();
    const [Authorized, setAuthorized] = useState<boolean>(false);
    const [Error, setError] = useState<boolean>(false);
    
    const [LoadingSt, setLoading] = useState<boolean>(true);
    const [ChartLayout, setChartLayout] = useState<string>('top');
    const [showConnection, setShowConnection] = useState<boolean>(false);
    const [currentView, setCurrentView] = useState<string>('graph');

    const refreshServiceData = async (): Promise<void> => {
        try {
            // Fetch applications data from the service
            const applications: ApplicationsDto | null = await new ApplicationsService().get();
            
            // Exit early if no applications data is received
            if (!applications) return;

            // Find the specific application based on the key
            const app = applications.webApps?.find((f) => f.key === key);
            
            // Exit early if no matching application is found
            if (!app) return;

            // Update the state with the fetched application data
            setApplication(app);

            // Determine the current environment, using 'env' or defaulting to the first available environment
            let currentEnv = env ?? app.environments[0].name;
            setCurrentEnvironment(currentEnv);
            
            // Fetch health graph data for the current service and environment
            const healthGraph: HealthGraphDto | null = await new HealthGraphService().get(key, currentEnv);

            // Update the state with the fetched health graph data if it's available
            if (healthGraph) {
                setAuthorized(true);
                setError(false);
                try {
                    setHealthGraphDto(healthGraph.service);
                }
                catch (reason: any) {
                    // Set authorization to false in case of an error
                    setError(true);
                }
            } else {
                setAuthorized(false);
            }
        } catch (reason: any) {
            // Set authorization to false in case of an error
            if ((reason+"").indexOf("No Data") > 0)
            {
                setAuthorized(true);
                setError(true);
            }
            else {
                setAuthorized(false);
            }
        } finally {
            // Ensure the loading state is set to false, regardless of success or failure
            setLoading(false);
        }
    }
    
    useEffect(() => {
        refreshServiceData();
    }, [env]);
    
  
    const changeViewConnection = (): void => {
        setShowConnection(!showConnection);
    }

    const ChangeLayout = () => {
        const newLayout = ChartLayout === 'top' ? 'left' : 'top';
        window.chart.layout(newLayout).render().fit();
        setChartLayout(newLayout);
    }

    const filterChart = (term: string): void => {
        const _chart = window.chart;
        setShowSearchText(term);
      
        // Clear existing highlighting
        _chart.clearHighlighting();
      
        // Update data with new highlighting
        const updatedData = _chart.data().map(d => ({
          ...d,
          _highlighted: term ? d.name.toLowerCase().includes(term) : false
        }));
      
        // Render the chart with updated data
        _chart.data(updatedData).render().fit();
      }

      const changeEnvironment = (env: string) =>  {
        navigate("/healths/"+key+"/"+(env));
        setCurrentEnvironment(env);
      };
      const changeSearch = (term: string) => filterChart(term);

    return (

        <div>
            {LoadingSt === true ? <Loading isLoading= {LoadingSt} />
            :
            Authorized == true && Error == false ? 
            <>
                <HealthMenu 
                useAll={false} 
                layout="healths"
                application={application!} 
                currentEnvironment={currentEnvironment!} 
                currentView={currentView} 
                changeEnvironment={changeEnvironment} 
                changeSearch={changeSearch}></HealthMenu>
                <div className='healthTreeContainer'>
                    <div className='layoutOptions'>
                    <button type="button" className="btn btn-outline-primary btn-icon-end " onClick={ ChangeLayout }>Switch layout <i className="material-icons-round">pivot_table_chart</i></button>
                    <div className="form-check form-check form-switch mb-4 align-items-center ms-4" style={{ display: "inline-flex" }}>
                        <label className="form-check-label">Show all connections</label>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckWithLabel" checked={!showConnection ? {} : {true: Boolean}}  onChange={ changeViewConnection }  />
                    </div>
                </div>
                    <HealthTree service={healthGraphDto!} showConnection={showConnection} refreshServiceData={refreshServiceData} />
                </div>
            </> : ( Error == false ? <UnauthorizedAccess /> : 
            <>
                <HealthMenu 
                useAll={false} 
                layout="healths"
                application={application!} 
                currentEnvironment={currentEnvironment!} 
                currentView={currentView} 
                changeEnvironment={changeEnvironment} 
                changeSearch={changeSearch}></HealthMenu>
                <div className='healthTreeContainer'>
                    <div className='layoutOptions'>
                </div>
                <div className='mt-5'>
                    <NoValueCompute />
                </div>
                </div>
            </>
                )
            }
        </div>
    );
}