import React, { type FC } from 'react';

interface Props {
}

const NoValueCompute: FC<Props> = ({
}: Props) =>
    <section className="flex-center">
 
    <div className="empty-state">
      <i className="icon icon-lg">text_snippet</i>
      <h4>No data to display</h4>
      <p>Your application is not yet configured and/or the scan haven't run yet.<br/>Check your configuration and come back in few hours.</p>
    </div>
   
  </section>

export default NoValueCompute;
