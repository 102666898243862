import { type ReactElement, useEffect, useState } from 'react';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { ApiConfigurationService } from '@/services/ApiConfigurationService.ts'
import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type APIEntryDto } from '@/models/apiConfiguration.ts'
import ApplicationCard from './applicationCard-v2.tsx'
import SettingsForm from './settingsForm.tsx'
import Loading from '../common/Loading.tsx';
import './home.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardBody, CardHeader, ButtonGroup } from 'reactstrap';
import NoValue from '../common/NoValue.tsx';

export function ConnectedHome(): ReactElement {

    
    const [applications, setApplications] = useState<ApplicationsDto | null>(null);
    const [bookmarks, setBookmarks] = useState<ApplicationDto[] | null>(null);
    const [filter, setFilter] = useState('');
    const [filterDepartment, setFilterDepartment] = useState('');
    const [filterHealth, setFilterHealth] = useState('');
    const [filterConfigured, setFilterConfigured] = useState('');
    
    const [filterHosting, setFilterHosting] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalSettings, setModalSettings] = useState(false);
    const [isBookmarkedView, setIsBookmarkedView] = useState(false);
    const [modalErrorSettings, setModalErrorSettings] = useState(false);
    const toggle = () => setModalErrorSettings(!modalErrorSettings);
    
    const [selectedApplication, setSelectedApplication] = useState<ApplicationDto | null>(null);

    const RefreshData = () => {
        setIsLoading(true);
        new ApplicationsService().get().then((applications: ApplicationsDto) => {
            const bookmarks = applications?.webApps
                ?.filter(
                  (f) =>
                    f.isBookmarked
                )
            if (applications?.webApps.filter((f) => f.isBookmarked).length > 0) {
                setIsBookmarkedView(true);
            }
            setIsLoading(false);
            setBookmarks(bookmarks);
            setApplications(applications);
        });
    };
    

  const ToggleBookmarks = (e: any, application: ApplicationDto, currentStatus: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    new ApplicationsService().toggleBookmarks(application.key, currentStatus).then((result: boolean) => {
        RefreshData();
    });
    return false;
  }


  const FilterService = () => {
    return applications?.webApps
        ?.filter(
            (f) => f != null &&
                (f.name.toLowerCase().includes(filter.toLowerCase()) ||
                    f.managerDepartment?.toLowerCase().includes(filter.toLowerCase()) ||
                    filter === '')
                && (filterDepartment === '' || (f.managerDepartment != null && filterDepartment != '' && f.managerDepartment.toLowerCase().includes(filterDepartment.toLowerCase())))
                && (filterHealth === '' || (f.status != null && filterHealth != '' && f.status.toLowerCase().includes(filterHealth.toLowerCase())))
                && (filterConfigured === '' || (filterConfigured != '' && (f.isConfigured + '').toLowerCase().includes(filterConfigured.toLowerCase())))
                && (filterHosting === '' || (f.hosting != null && f.hosting !== null && filterHosting != '' && f.hosting.toLowerCase().includes(filterHosting.toLowerCase())))
                && (isBookmarkedView === false || (isBookmarkedView && f.isBookmarked))
        );
    }

  const EditSettings = (e: any, application: ApplicationDto) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedApplication(application);
    setModalSettings(!modalSettings);
    return false;
  };

  const CloseSettings = () => setModalSettings(!modalSettings);

  const SaveSettings = (producerCode: string | undefined, apiEntries : APIEntryDto[]) => {
    new ApiConfigurationService().saveSettings(selectedApplication, producerCode, apiEntries);
    CloseSettings();
    RefreshData();
  };
  
  

    useEffect(() => {
        
        const dataFilter = window.localStorage.getItem('Filter');
        if ( dataFilter !== null ) setFilter(dataFilter);
        const dataFilterHealth = window.localStorage.getItem('FilterHealth');
        if ( dataFilterHealth !== null ) setFilterHealth(dataFilterHealth);
        const dataFilterConfigured = window.localStorage.getItem('FilterConfigured');
        if ( dataFilterConfigured !== null ) setFilterConfigured(dataFilterConfigured);
        const dataFilterDepartment = window.localStorage.getItem('FilterDepartment');
        if ( dataFilterDepartment !== null ) setFilterDepartment(dataFilterDepartment);
        const dataFilterHosting = window.localStorage.getItem('FilterHosting');
        if ( dataFilterHosting !== null ) setFilterHosting(dataFilterHosting);
        
        RefreshData();
    }, []);
  
    return (
        <div>
            <h2>Services</h2>
            <Row className='mt-3'>
                <Col sm="3">
                    <Card className='card card-bordered'>
                        <CardHeader className='p-3 pb-0'>
                        <span className="material-icons-round">filter_list</span><h5 className='header-with-icon'>Filters</h5>
                        </CardHeader>
                        <CardBody className='p-3 pt-0'>
                            <div className="row d-flex">
                                <div className="input-group">
                                    <div className="col-md input-group  p-2">
                                        
                                        <label className="form-label">Filter</label>
                                        <div className="input-group">
                                            <div className="input-icon-start">
                                                <em className="icon">search</em>
                                            </div>
                                            <input
                                            type="text"
                                            className="form-control w-100"
                                            id="filter"
                                            name="filter"
                                            value={filter}
                                            onChange={(event) => {
                                                    setFilter(event.target.value)
                                                    window.localStorage.setItem('Filter', event.target.value);
                                                }
                                            }
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-md-12 input-group  p-2">
                                        <label className="form-label">Health</label>
                                        <select className="form-select w-100" id='select_health'  value={filterHealth}
                                            onChange={(event) => {
                                                    setFilterHealth(event.target.value);
                                                    window.localStorage.setItem('FilterHealth', event.target.value);
                                                }
                                            }>
                                            <option value="">All</option>
                                            <option value="">All</option>
                                            <option value="Healthy">Healthy</option>
                                            <option value="Unhealthy">Unhealthy</option>
                                            <option value="Degraded">Degraded</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 input-group  p-2">
                                        <label className="form-label">Is Configured</label>
                                        <select className="form-select w-100" id='select_configured' value={filterConfigured}
                                            onChange={(event) => {
                                                    setFilterConfigured(event.target.value);
                                                    window.localStorage.setItem('FilterConfigured', event.target.value);
                                                }
                                            }>
                                            <option value="">All</option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 input-group  p-2">
                                        <label className="form-label">Department</label>
                                        <select className="form-select w-100" id='select_department' value={filterDepartment}
                                            onChange={(event) => {
                                                    setFilterDepartment(event.target.value);
                                                    window.localStorage.setItem('FilterDepartment', event.target.value);
                                                }
                                            }>
                                            <option value="">All</option>
                                            <option value="GBTO/CIO/SGM/DPO">GBTO/CIO/SGM/DPO</option>
                                            <option value="GBTO/CIO/SGM/SOL">GBTO/CIO/SGM/SOL</option>
                                            <option value="GBTO/CIO/SGM/DCI">GBTO/CIO/SGM/DCI</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 input-group  p-2">
                                    <label className="form-label">Hosting</label>
                                        <select className="form-select w-100" id='select_hosting'  value={filterHosting}
                                            onChange={(event) => {
                                                    setFilterHosting(event.target.value);
                                                    window.localStorage.setItem('FilterHosting', event.target.value);
                                                }
                                            }>
                                            <option value="">All</option>
                                            <option value="AWS">AWS</option>
                                            <option value="Azure">Azure</option>
                                            <option value="DTZone">DT-Zone</option>
                                            <option value="GTS-LAN">GTS-LAN</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="9">
                    <Row className='mb-2'>
                        <Col sm="3">
                        <ButtonGroup className='btn-group btn-group-toggle btn-group-lg'>
                            <Button
                            color="primary"
                            className='btn btn-toggle-primary btn-icon-start'
                            outline
                            onClick={() => setIsBookmarkedView(false)}
                            active={!isBookmarkedView}
                            >
                            All Services
                            </Button>
                            <Button
                            className='btn btn-toggle-primary btn-icon-start'
                            outline
                            onClick={() => setIsBookmarkedView(true)}
                            active={isBookmarkedView}
                            >
                            <i className="icon">bookmarks</i>Bookmarks
                            </Button>
                        </ButtonGroup>
                        </Col>
                    </Row>
                    
                <Loading isLoading={isLoading} />
                {isLoading == false && FilterService()
                ?.map((service) => (
                    <ApplicationCard key={`app_card_${service.key}`} service={service} refreshData={RefreshData} toggleBookmarks={ToggleBookmarks} editSettings={EditSettings} showWarning={toggle}></ApplicationCard>
                ))}
                {isLoading == false && FilterService()?.length == 0 ? <NoValue /> : null}
               </Col>
            </Row>
            <Modal isOpen={modalSettings} size='xl'>
                <SettingsForm application={selectedApplication} closeModal={CloseSettings} save={SaveSettings} />
            </Modal>
            <Modal isOpen={modalErrorSettings}  toggle={toggle}>
                <div  className='shadow-max border border-danger'>
                <ModalHeader  toggle={toggle}>Warning</ModalHeader>
                <ModalBody>
                    This application is not yet configured, you won't be able to see health details.
                </ModalBody>
                </div>
            </Modal>
        </div>
    );
}